import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Income from "./Income"; // Import the Income component

function Costs({ documentId, onBack }) {
  const [costs, setCosts] = useState([
    {
      supplierName: "",
      VATable: "Yes",
      forecastPaymentDays: 0,
      amounts: Array(12).fill(0), // Initialize amounts for 12 months
    },
  ]);
  const [step, setStep] = useState(1); // State to manage the step

  const handleAddCost = () => {
    console.log("Adding a new cost row");
    setCosts([
      ...costs,
      {
        supplierName: "",
        VATable: "Yes",
        forecastPaymentDays: 0,
        amounts: Array(12).fill(0),
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newCosts = [...costs];
    newCosts[index][field] = value;
    setCosts(newCosts);
  };

  const handleAmountChange = (index, month, value) => {
    const newCosts = [...costs];
    newCosts[index].amounts[month] = value === "" ? "" : parseFloat(value);
    setCosts(newCosts);
  };

  const handleDeleteCost = (index) => {
    console.log("Deleting cost row at index:", index);
    setCosts(costs.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (!documentId) {
      console.error("Document ID is required to save costs.");
      return;
    }
    try {
      console.log("Saving costs for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { costs });
      console.log("Costs saved successfully");
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleNext = async () => {
    try {
      await handleSave();
      setStep(2); // Move to the next step
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  return (
    <div className="w-full text-gray-800 max-w-full px-4 sm:px-8">
      {step === 1 && (
        <>
          <p className="text-gray-800 self-start text-xl sm:text-2xl my-4">
            Enter your costs
          </p>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr>
                  <th className="px-1 py-2 text-sm font-semibold text-left">
                    Supplier Name
                  </th>
                  <th className="px-1 py-2 text-xs font-semibold text-left">
                    VATable?
                  </th>
                  <th className="px-1 py-2 text-xs font-semibold text-left">
                    Forecast Payment Days
                  </th>
                  {Array.from({ length: 12 }, (_, i) => (
                    <th
                      key={i}
                      className="px-1 py-2 text-xs font-light text-left"
                    >
                      Month {i + 1}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {costs.map((cost, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-1 py-2">
                      <input
                        className="p-2 text-sm rounded-lg border border-gray-300 w-50 h-12"
                        type="text"
                        value={cost.supplierName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "supplierName",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td className="px-1 py-2">
                      <select
                        className="p-2 text-sm rounded-lg border border-gray-300 w-fit h-12"
                        value={cost.VATable}
                        onChange={(e) =>
                          handleInputChange(index, "VATable", e.target.value)
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </td>
                    <td className="px-1 py-2">
                      <input
                        className="p-2 text-sm rounded-lg border border-gray-300 w-full h-12"
                        type="number"
                        value={cost.forecastPaymentDays}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "forecastPaymentDays",
                            e.target.value ? parseInt(e.target.value) : ""
                          )
                        }
                      />
                    </td>
                    {Array.from({ length: 12 }, (_, i) => (
                      <td key={i} className="px-1 py-2">
                        <input
                          className="p-2 text-sm rounded-lg border border-gray-300 w-full h-12"
                          type="number"
                          value={cost.amounts[i]}
                          onChange={(e) =>
                            handleAmountChange(index, i, e.target.value)
                          }
                        />
                      </td>
                    ))}
                    <td className="px-4 py-2">
                      <button
                        onClick={() => handleDeleteCost(index)}
                        className=" text-xs text-red-500"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col sm:flex-row justify-between mb-4 space-y-2 sm:space-y-0">
            <button
              className="bg-blue-500 text-white p-2 text-sm rounded-lg"
              onClick={handleAddCost}
            >
              Add another Cost
            </button>
            <div className="flex space-x-2">
              <button
                className="bg-green-500 text-white p-2 text-sm rounded-lg"
                onClick={onBack}
              >
                Back
              </button>
              <button
                className="bg-green-500 text-white p-2 text-sm rounded-lg"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-yellow-500 text-white p-2 text-sm rounded-lg"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <Income documentId={documentId} onBack={() => setStep(1)} />
      )}
    </div>
  );
}

export default Costs;
