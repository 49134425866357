import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import Costs from "./components/Costs";
import Income from "./components/Income";
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/costs" element={<Costs />} />
        <Route path="/income" element={<Income />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
