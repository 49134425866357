import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCB0_k74qHLgcx2nNYiLo-rlh_uau7eg-0",
  authDomain: "forecast-project-fe657.firebaseapp.com",
  projectId: "forecast-project-fe657",
  storageBucket: "forecast-project-fe657.appspot.com",
  messagingSenderId: "1028853874311",
  appId: "1:1028853874311:web:9a221e55ecc9da84a00222"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };