import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

function BankForecast({ documentId, onBack }) {
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!documentId) return;
      try {
        const projectRef = doc(db, "projects", documentId);
        const projectSnap = await getDoc(projectRef);
        if (projectSnap.exists()) {
          setProjectData(projectSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (e) {
        console.error("Error fetching document:", e);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [documentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!projectData) {
    return <div>No data available</div>;
  }

  const calculateTotals = (dataArray) => {
    const totals = Array(12).fill(0);
    dataArray.forEach((entry) => {
      entry.amounts.forEach((amount, index) => {
        totals[index] += amount;
      });
    });
    return totals;
  };

  const salesReceipts = calculateTotals(projectData.income || []);
  const paymentsToSuppliers = calculateTotals(projectData.costs || []);

  return (
    <div className="text-center bg-gray-100 min-h-screen flex flex-col items-center text-white">
      <div className="w-full text-gray-800 px-4 sm:px-8">
        <p className="text-gray-800 self-start text-2xl my-4">
          Bank Forecast Summary
        </p>
        <div className="mb-4">
          <p>{projectData.businessName}</p>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="px-2 py-2 text-sm font-semibold text-left"></th>
                {Array.from({ length: 12 }, (_, i) => (
                  <th
                    key={i}
                    className="px-2 py-2 text-xs font-light text-left"
                  >
                    Month {i + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-2 text-sm py-2 whitespace-nowrap">
                  Sales Receipts
                </td>
                {salesReceipts.map((total, index) => (
                  <td key={index} className="px-2 text-sm py-2">
                    £{total}
                  </td>
                ))}
              </tr>
              <tr className="border-b">
                <td className="px-2 text-sm py-2 whitespace-nowrap">
                  Payments to Suppliers
                </td>
                {paymentsToSuppliers.map((total, index) => (
                  <td key={index} className="px-2 text-sm py-2">
                    £{total}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <button
          className="bg-green-500 text-white p-2 text-sm rounded-lg mt-4"
          onClick={onBack}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default BankForecast;
