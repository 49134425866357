import { useState } from "react";
import { collection, addDoc, doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";
import Costs from "./components/Costs";
import Income from "./components/Income";
import BankForecast from "./components/BankForecast"; // Import the BankForecast component

function App() {
  const [businessName, setBusinessName] = useState("");
  const [step, setStep] = useState(1);
  const [documentId, setDocumentId] = useState(null);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      console.log("Creating project with business name:", businessName);
      const docRef = await addDoc(collection(db, "projects"), {
        businessName: businessName,
        createdAt: serverTimestamp(),
      });
      console.log("Project created successfully with ID:", docRef.id);
      setDocumentId(docRef.id);
      setStep(2);
    } catch (e) {
      console.error("Error adding document:", e);
    }
  };

  const handleSaveCosts = async (newCosts) => {
    if (!documentId) return;
    try {
      console.log("Saving costs for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { costs: newCosts });
      setStep(3);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleSaveIncome = async (newIncome) => {
    if (!documentId) return;
    try {
      console.log("Saving income for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { income: newIncome });
      setStep(4);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="text-center">
      <header
        className="bg-gray-100 min-h-screen flex flex-col items-center text-white px-4 sm:px-8"
        style={{
          fontSize: "calc(10px + 2vmin)",
        }}
      >
        {step === 1 && (
          <>
            <p className="text-gray-800 self-start text-2xl mt-4">
              Create a business plan in minutes.
            </p>
            <div className="flex-grow flex items-center justify-center w-full">
              <div className="w-full sm:w-1/3 flex flex-col">
                <p className="text-gray-800 self-start mb-4 text-lg sm:text-xl">
                  Enter your business name.
                </p>
                <form
                  className="flex flex-col sm:flex-row text-black items-center gap-3 justify-center"
                  onSubmit={handleCreate}
                >
                  <input
                    className="p-2.5 text-lg rounded-lg cursor-pointer w-full border-2 border-gray-800"
                    type="text"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="w-full sm:w-auto bg-gray-800 p-2.5 text-lg rounded-lg cursor-pointer text-gray-100 shadow-none border-2 border-black no-underline mt-2 sm:mt-0"
                  >
                    Create
                  </button>
                </form>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <Costs
            documentId={documentId}
            onBack={handleBack}
            onSave={handleSaveCosts}
          />
        )}
        {step === 3 && (
          <Income
            documentId={documentId}
            onBack={handleBack}
            onSave={handleSaveIncome}
          />
        )}
        {step === 4 && (
          <BankForecast
            documentId={documentId}
            onBack={handleBack}
          />
        )}
      </header>
    </div>
  );
}

export default App;